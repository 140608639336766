.teamPageLayout {
  padding: 12px 24px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.teamTableHeader {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  .ant-input-search {
    width: 400px;

    .ant-input {
      height: 32px;
    }
  }
}
