.variablesListContainer {
  background-color: #18191b;
  padding-top: 8px;

  & > span {
    font-weight: 700;
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 8px;
  }

  .variablesList {
    background-color: #303236;
    margin-top: 16px;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 12px;

    .variableItem {
      width: 100%;
      .variableLabel {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-weight: 500;
        .dot {
          width: 8px;
          min-width: 8px;
          height: 8px;
          min-height: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}
