@import "../pages/styles.scss";
@import "../components/styles.scss";
@import "./admin.scss";
@import "./dock-view/dockview.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;900&display=swap");

* {
  font-family: "Inter";
}

body {
  overflow-x: hidden;
  background-color: #0c0d0d;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.ant-input-number {
  width: 100%;
}

.ant-picker {
  width: 100%;
}

.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
}

.ant-checkbox-inner::after {
  width: 8px !important;
  height: 13px !important;
}
