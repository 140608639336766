.layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > .ant-row {
    height: 92%;
  }
}

.logoutButton {
  margin-top: auto;
  margin-left: auto;
}
