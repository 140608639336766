@import "../../styles/variables.scss";

.sidebar {
  overflow: hidden;
  max-height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  transition: none !important;

  & .dark {
    background-color: $darkBg !important;
    color: $darkPrimary;
  }
}

.sidebarUserName {
  margin-top: auto;
  padding: 8px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.sidebarContainer {
  position: relative;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  align-items: center;
  gap: 16px;
  margin-right: 4px;

  .logoutButton {
    width: 100%;
  }
}

.siderHolder {
  position: absolute;
  right: -12px;
  top: 0;
  width: 24px;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  cursor: col-resize;

  .line {
    background-color: transparent;
    width: 2px;
    height: 100%;
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    .line {
      background-color: orange;
      width: 4px;
    }
  }
}

.sidebarHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  gap: 24px;
}

.sidebarHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 8px;

  button {
    font-size: 24px;
  }

  &Collapsed {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    button {
      font-size: 20px;
    }
  }
}

.sidebarButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 16px;

  &Collapsed {
    @extend .sidebarButtons;
    flex-direction: column;
    gap: 4px;
  }
}

.sidebarContent {
  width: 100%;
  display: flex;
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;

  .sidebarBusinessUnits {
    width: 20%;
    max-width: 80px;
    min-width: 80px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 4px;
    box-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.1);

    .ant-tabs {
      display: inline;
    }

    .ant-tabs-tab {
      font-size: 24px;
      padding: 8px;
    }

    .ant-tabs-content-holder {
      display: none;
    }
  }

  .ant-menu {
    background-color: transparent;
    .ant-menu-item {
      border-radius: 0;
      background-color: #18191b;
      &-selected {
        border-left: 4px solid #0180ff;
      }
    }
  }
}

.logo {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sidebarFooter {
  height: fit-content;
  margin-top: auto;
}

.sidebarMenu {
  width: 95%;
  min-width: 200px;
  height: 100%;
  overflow: hidden;

  .ant-tabs {
    height: 100%;

    .ant-tabs-content-holder {
      height: 100%;

      .ant-tabs-content {
        height: 100%;
      }

      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }

  .ant-tabs-tabpane {
    max-width: 95%;
  }

  .ant-tabs-tab {
    width: 50%;
    display: flex;
    justify-content: center;
    font-size: 32px;
    padding: 0px !important;
    border-radius: 0 !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }
}
