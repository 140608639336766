@import "../../styles/variables.scss";

.menuContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  & > button {
    margin-top: auto;
  }
}

.LayoutContentContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;

  &.dark {
    background-color: $darkBg;
    color: $darkPrimary;
  }
}

.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
  transition: none !important;
}
