.login-page {
  .authentication-request-magic-link {
    float: right;
  }
}

.loginBackground {
  background-color: #0c0d0d;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.adminLoginContent {
  width: 400px;
}

.adminLoginHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;

  & > span {
    font-size: 36px;
    color: #fff;
    font-family: "Playfair Display" !important;
  }
}

.oktaLoginButton {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 10px;
  height: 40px;
  margin-bottom: 60px;
}

.adminLoginCircle {
  position: absolute;
  left: 0;

  animation: moveCircle 8s infinite ease-in-out;
}

.adminLoginStrippedCircle {
  position: absolute;
  right: 0;
  top: 0;
  animation: moveStrippedCircle 8s infinite ease-in-out;
}

.adminLoginTriangles {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: moveTriangles 8s infinite ease-in-out;
}

.loginForm {
  color: white;

  & input {
    border-bottom: 1px solid rgba(255, 255, 255, 0.302);

    &::placeholder {
      color: rgba(255, 255, 255, 0.302);
    }
  }

  & button {
    width: 100%;
    margin-top: 20px;
    border: 1px solid rgba(255, 255, 255, 0.302);
    color: white;

    &:hover {
      background-color: #fff !important;
      color: #000 !important;
    }
  }
}

// create a subtle animation for the circle, which moves it up and down easily

@keyframes moveCircle {
  0% {
    top: 0;
  }
  50% {
    top: 40px;
  }
  100% {
    top: 0;
  }
}

@keyframes moveStrippedCircle {
  0% {
    top: 0;
    transform: rotate(0deg);
  }
  50% {
    top: -40px;
    transform: rotate(5deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes moveTriangles {
  0% {
    bottom: 0;
  }
  50% {
    bottom: -40px;
  }
  100% {
    bottom: 0;
  }
}
