.header {
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  padding: 5px 40px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;

  .buttons {
    margin-left: 2rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .userSettings {
    width: min-content;
    text-align: center;
    font-weight: 600;
    margin-left: auto;
  }

  .userName {
    cursor: pointer;
  }
}
