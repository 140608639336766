@import "../../styles/variables.scss";

.addEditPanelModal {
  position: relative;

  .tabs {
    position: absolute;
    top: -30px;
    left: 0;
    display: flex;
  }

  .tab {
    background-color: #424242;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 30px;
    color: white;
    cursor: pointer;
    transition: all 0.1s;

    &.active {
      background-color: $primary;
      color: white;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.683);
      z-index: 20;
    }
  }
}

.panelFiltersContainer {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    color: #747474;
    border-bottom: 2px solid #747474;
    min-height: fit-content;
    height: fit-content;
    padding: 10px 0;

    & span:nth-child(1) {
      width: 8%;
    }

    & span:nth-child(2) {
      width: 20%;
    }

    & span:nth-child(3) {
      width: 20%;
    }

    & span:nth-child(4) {
      width: 20%;
    }

    & span:nth-child(5) {
      width: 30%;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 16px;

    .filter {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;

      & > *:nth-child(1) {
        width: 7%;
        max-width: 7%;
        min-width: 7%;
      }

      & > *:nth-child(2) {
        width: 20%;
        max-width: 20%;
        min-width: 20%;
      }

      & > *:nth-child(3) {
        width: 20%;
        max-width: 20%;
        min-width: 20%;
      }

      & > *:nth-child(4) {
        width: 20%;
        max-width: 20%;
        min-width: 20%;
      }

      & > *:nth-child(5) {
        width: 30%;
        max-width: 30%;
        min-width: 30%;
      }
    }
  }
}

.variableOption {
  display: flex;
  align-items: center;
  gap: 10px;
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    min-width: 8px;
    min-height: 8px;
  }
}
