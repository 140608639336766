@import "../../../styles/variables.scss";

.folderListItem {
  gap: 0.5rem;
  margin-bottom: 4px;
  border-radius: 0;
  padding-right: 0;
  padding-left: 4px;
  &Hover {
    background-color: rgba(105, 105, 105, 0.288);
  }

  .folderHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 32px;
    min-height: 32px;
    padding-left: 8px;

    .text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .moreButton {
      display: none;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;

      button {
        padding: 4px !important;
        width: 32px !important;
        transition: background-color 0.1s ease-in-out;
      }
    }

    &:hover {
      .moreButton {
        display: flex;
      }
    }
  }

  .folderHeaderActive {
    @extend .folderHeader;
    border-left: 2px solid $primary;
    padding-left: 6px;
  }
}

.workspaceListItem {
  border-left: 2px solid grey;
  display: flex;
  align-items: center;
  padding: 0 4px;
  padding-left: 12px;
  height: 30px;
  min-height: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  &:hover {
    background-color: rgba(105, 105, 105, 0.288);
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .holder {
    visibility: hidden;
    margin-left: auto;
  }

  &:hover {
    .holder {
      visibility: visible;
    }
  }

  .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.active {
    background-color: $primary;
    .title {
      font-weight: bold;
    }
  }
}

.ant-collapse-header {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.ant-collapse-header-text {
  width: 75%;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.list {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 20px 0;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0 !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.drawerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.businessUnitHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  min-height: 32px;
  font-size: 16px;
  padding-left: 10px;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .moreButton {
    display: none;

    .showMore {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    .moreButton {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.businessUnitHeaderActive {
  @extend .businessUnitHeader;
  border-left: 4px solid $primary;
  padding-left: 6px;
}

.businessUnitListItem {
  padding: 8px 4px;
  background-color: #18191b;

  &Hover {
    background-color: rgba(105, 105, 105, 0.288);
  }
}

.businessUnitFoldersCollapse {
  padding: 8px;
  margin: 4px 0;
}

.noBusinessUnitAvailable {
  text-align: center;
  padding-top: 10px;
}

.emptyFolder {
  margin-bottom: 10px;
}

.topLevelActionHolder {
  button {
    padding-right: 4px;
    padding-left: 4px;
  }
}
