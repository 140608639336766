@import "../../../styles/variables.scss";

.workspaceContainer {
  height: calc(100vh - 55px);
  width: 100%;
  position: relative;

  .addFirstPanelButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}

.addPanelButton {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
}

.panelHeader {
  display: flex;
  justify-content: space-between;
  height: 36px;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.5rem;

  &SettingsButton {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #81818149;
    }
  }

  &SettingsMenuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    gap: 1rem;
    cursor: pointer;

    &:hover {
      background-color: #89898949;
      border-radius: 4px;
    }
  }
}

.panelGroupActionButton {
  width: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > span {
    font-size: 1rem;
  }

  &:hover {
    background-color: #89898949;
    border-radius: 4px;
  }
}

.ant-popover-inner {
  padding: 4px !important;
}

.tab {
  border-radius: 10px 10px 0 0 !important;
}

.dockview-theme-dark {
  --dv-background-color: black;
  --dv-paneview-active-outline-color: dodgerblue;
  --dv-tabs-and-actions-container-font-size: 13px;
  --dv-tabs-and-actions-container-height: 35px;
  --dv-drag-over-background-color: rgba(83, 89, 93, 0.5);
  --dv-drag-over-border-color: white;
  --dv-tabs-container-scrollbar-color: #888;
  --dv-icon-hover-background-color: rgba(90, 93, 94, 0.31);
  --dv-floating-box-shadow: 8px 8px 8px 0px rgba(83, 89, 93, 0.5);
  --dv-group-view-background-color: #181818 !important;
  --dv-tabs-and-actions-container-background-color: #0c0d0d !important;
  --dv-activegroup-visiblepanel-tab-background-color: #0066ff !important;
  --dv-activegroup-hiddenpanel-tab-background-color: #2d2d2d;
  --dv-inactivegroup-visiblepanel-tab-background-color: #0080ff !important;
  --dv-inactivegroup-hiddenpanel-tab-background-color: #424242 !important;
  --dv-tab-divider-color: #1e1e1e;
  --dv-activegroup-visiblepanel-tab-color: white !important;
  --dv-activegroup-hiddenpanel-tab-color: white !important;
  --dv-inactivegroup-visiblepanel-tab-color: white !important;
  --dv-inactivegroup-hiddenpanel-tab-color: white !important;
  --dv-separator-border: #0c0d0d !important;
  --dv-paneview-header-border-color: #0c0d0d !important;
}
.dockview-theme-light {
  --dv-background-color: black;
  --dv-paneview-active-outline-color: dodgerblue;
  --dv-tabs-and-actions-container-font-size: 13px;
  --dv-tabs-and-actions-container-height: 35px;
  --dv-drag-over-background-color: rgba(83, 89, 93, 0.5);
  --dv-drag-over-border-color: white;
  --dv-tabs-container-scrollbar-color: #888;
  --dv-icon-hover-background-color: rgba(90, 93, 94, 0.31);
  --dv-floating-box-shadow: 8px 8px 8px 0px rgba(83, 89, 93, 0.5);
  --dv-group-view-background-color: white;
  --dv-tabs-and-actions-container-background-color: #f3f3f3;
  --dv-activegroup-visiblepanel-tab-background-color: white;
  --dv-activegroup-hiddenpanel-tab-background-color: #ececec;
  --dv-inactivegroup-visiblepanel-tab-background-color: white;
  --dv-inactivegroup-hiddenpanel-tab-background-color: #ececec;
  --dv-tab-divider-color: white;
  --dv-activegroup-visiblepanel-tab-color: rgb(51, 51, 51);
  --dv-activegroup-hiddenpanel-tab-color: rgba(51, 51, 51, 0.7);
  --dv-inactivegroup-visiblepanel-tab-color: rgba(51, 51, 51, 0.7);
  --dv-inactivegroup-hiddenpanel-tab-color: rgba(51, 51, 51, 0.35);
  --dv-separator-border: rgba(128, 128, 128, 0.35);
  --dv-paneview-header-border-color: rgb(51, 51, 51);
}

.tabs-and-actions-container .tabs-container {
  scrollbar-width: unset !important;
}
