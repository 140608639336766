@import "../../styles/variables.scss";

.addEditWorkspaceModal {
  position: relative;

  .tabs {
    position: absolute;
    top: -30px;
    left: 0;
    display: flex;
  }

  .tab {
    background-color: #424242;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 30px;
    color: white;
    cursor: pointer;
    transition: all 0.1s;

    &.active {
      background-color: $primary;
      color: white;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.683);
      z-index: 20;
    }
  }
}
